<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-data-table
          :headers="headers"
          :items="reglasComercialesRegistros"
          class="elevation-1"
          :search="search"
          item-key="rgId"
          show-expand
          :expanded.sync="expanded"
          :loading="isLoading"
        >
          <template v-slot:[`item.acumulable`]="{ item }">
            <v-icon
              v-if="item.acumulable"
              small
              color="primary"
              class="d-flex justify-center"
            >
              {{ checkIcon }}
            </v-icon>
          </template>
          <template v-slot:top >
            <v-toolbar flat>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2" align="end" v-if="canCreate">
                  <v-btn color="primary" @click="openModal()"> Nuevo </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="16"
                  v-on="on"
                  @click="openModalLogRg(item.rgId)"
                >
                  {{ seeIcon }}
                </v-icon>
              </template>
              <span>Ver log de regla comercial</span>
            </v-tooltip>
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="16"
                  v-on="on"
                  @click="openModal(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar regla comercial</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="16"
                  v-on="on"
                  @click="deleteRg(item)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar regla comercial</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="handleExpansion(item, isExpanded)"
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  >{{
                    isExpanded ? "mdi-account-check" : "mdi-account"
                  }}</v-icon
                >
              </template>
              <span>Usuarios</span>
            </v-tooltip>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <strong>Fecha alta:</strong>
              {{ item.fecAlta }}
              <strong style="padding-left: 80px">Usuario alta:</strong>
              {{ item.usuAlta }}
              <strong style="padding-left: 80px">Fecha modificación:</strong>
              {{ item.fecModi }}
              <strong style="padding-left: 80px">Usuario modificación:</strong>
              {{ item.usuModi }}
            </td>
          </template>
        </v-data-table>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-5 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalLog"
      v-model="openModalLog"
      max-width="90%"
      @keydown.esc="closeModalLog"
      persistent
    >
      <LogReglasComerciales
        :reglaId="reglaId"
        @closeModalLog="closeModalLog"
      ></LogReglasComerciales
    ></v-dialog>
    <div v-if="openModalEdit">
      <v-dialog
        v-model="openModalEdit"
        max-width="50%"
        @keydown.esc="closeAndReload"
        persistent
      >
        <EditReglasComerciales
          :editRgItem="editRgItem"
          @closeAndReload="closeAndReload"
        ></EditReglasComerciales>
      </v-dialog>
    </div>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'30%'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditReglasComerciales from "@/components/modules/cuotas/configuracion/EditReglasComerciales.vue";
import LogReglasComerciales from "@/components/modules/cuotas/configuracion/LogReglasComerciales.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  components: {
    PageHeader,
    DeleteDialog,
    EditReglasComerciales,
    LogReglasComerciales,
    GoBackBtn,
    Ayuda
  },
  name: "ReglasComerciales",
  data: () => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    seeIcon: enums.icons.SEE,
    searchIcon: enums.icons.SEARCH,
    checkIcon: enums.icons.CHECK_OUTLINE,
    search: "",
    isLoading: false,
    showDeleteModal: false,
    idToDelete: null,
    showExpand: false,
    showHelp: false,
    showIcon: true,
    optionCode: enums.webSiteOptions.REGLAS_COMERCIALES,
    routeToGo: "DevengamientosCobranzas",
    title: enums.titles.REGLAS_COMERCIALES,
    titleDelete: "¿Eliminar regla comercial?",
    rules: rules,
    reglasComercialesRegistros: [],
    editRgItem: {},
    cambioRcItems: null,
    headers: [
      {
        text: "Nombre",
        value: "rgNombre",
        sortable: true
      },
      {
        text: "Descripción",
        value: "rgDescrip",
        sortable: false
      },
      {
        text: "Procedimientos de reglas comerciales",
        value: "rgProcNombre",
        sortable: false
      },
      {
        text: "Parámetros",
        value: "parametros",
        sortable: false
      },
      {
        text: "Orden",
        value: "orden",
        sortable: false
      },
      {
        text: "Período desde",
        value: "periodoVigenciaDesde",
        sortable: false
      },
      {
        text: "Período hasta",
        value: "periodoVigenciaHasta",
        sortable: false
      },
      {
        text: "Acumulable",
        value: "acumulable",
        sortable: false
      },

      { text: "Acciones", value: "actions", sortable: false, align: "end" },
      { text: "", value: "data-table-expand", align: "end" }
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    openModalEdit: false,
    openModalLog: false,
    expanded: []
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadReglasComerciales();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getReglasComerciales: "configuracion/getReglasComerciales",
      deleteReglaComercial: "configuracion/deleteReglaComercial",
      setAlert: "user/setAlert"
    }),
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVA_REGLA_COMERCIAL:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_REGLA_COMERCIAL:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_REGLA_COMERCIAL:
            this.canDelete = true;
            break;
          default:
            break;
        }
      });
    },
    async loadReglasComerciales() {
      this.isLoading = true;
      const data = await this.getReglasComerciales();
      this.reglasComercialesRegistros = data;
      this.isLoading = false;
    },

    deleteRg(item) {
      this.showDeleteModal = true;
      this.itemToDelete = item;
    },
    async confirmDelete() {
      const response = await this.deleteReglaComercial({
        rgId: this.itemToDelete.rgId
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadReglasComerciales();
      }
    },
    seeLogRg(item) {
      this.openModalImpuestoConcep = true;
      this.conceptoObject = item;
    },

    openModal(item) {
      this.openModalEdit = true;
      this.editRgItem = item;
    },

    openModalLogRg(reglaId) {
      this.openModalLog = true;
      this.reglaId = reglaId;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.loadReglasComerciales();
    },
    closeModalLog() {
      this.openModalLog = false;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
