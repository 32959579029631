var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formEditTitle))])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.save()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"type":"text","label":"Nombre","dense":"","outlined":"","rules":_vm.rules.required.concat([
                  _vm.rules.maxLength(_vm.rgNombre, 50),
                  _vm.rules.requiredTrim(_vm.rgNombre)
                ])},model:{value:(_vm.rgNombre),callback:function ($$v) {_vm.rgNombre=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"rgNombre"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Descripción de regla comercial","dense":"","outlined":"","rules":_vm.rules.required.concat([
                  _vm.rules.maxLength(_vm.rgDescrip, 50),
                  _vm.rules.requiredTrim(_vm.rgDescrip)
                ])},model:{value:(_vm.rgDescrip),callback:function ($$v) {_vm.rgDescrip=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"rgDescrip"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.rgProcCombo,"item-text":"rgProcDescripGral","item-value":"id","label":"Procedimiento","outlined":"","clearable":"","dense":"","return-object":"","rules":_vm.rules.required},on:{"input":function($event){return _vm.permitirAcumular(_vm.rgProcSelect)},"change":function($event){_vm.rgProcSelect
                  ? ((_vm.descProcNombre = _vm.rgProcSelect.rgProcDescripGral),
                    _vm.resetParametro())
                  : (_vm.descProcNombre = null)}},model:{value:(_vm.rgProcSelect),callback:function ($$v) {_vm.rgProcSelect=$$v},expression:"rgProcSelect"}})],1)],1),(_vm.rgProcSelect)?_c('p',[_c('i',[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" Descripción general del procedimiento disponible seleccionado: "+_vm._s(_vm.descProcNombre))])])]):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[(_vm.rgProcSelect)?_c('p',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{ref:"parametros",attrs:{"outlined":"","type":"text","dense":"","label":"Parámetros","items":_vm.rgProcCombo,"item-text":"value","clearable":"","rules":_vm.rules.required.concat([
                        _vm.rules.maxLength(_vm.parametros, 150)
                      ]),"placeholder":("Ej. " + (_vm.rgProcSelect != null
                          ? _vm.rgProcSelect.rgProcEjemploParametros
                          : '')),"hint":("Ej. " + (_vm.rgProcSelect != null
                          ? _vm.rgProcSelect.rgProcEjemploParametros
                          : '')),"persistent-hint":""},model:{value:(_vm.parametros),callback:function ($$v) {_vm.parametros=$$v},expression:"parametros"}})],1),_c('v-col',{staticClass:"pa-0 mt-1",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"right":"","max-width":"40%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.infoIcon)+" ")])]}}],null,false,1689461815)},[_c('span',[_vm._v(" Ingrese los parámetros correspondientes en el siguiente orden: ")]),_c('ol',[_c('ul',[(_vm.rgProcSelect != null)?_c('li',[_vm._v(" "+_vm._s(_vm.rgProcSelect.rgProcDescripTotal)+" ")]):_vm._e()])])])],1)],1)],1):_vm._e()])],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"label":"Período desde","dense":"","outlined":"","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)","rules":_vm.rules.required.concat(
                  [_vm.rules.validDateRange(_vm.periodoDesde, _vm.periodoHasta)],
                  _vm.rules.periodoYyyyMm
                )},model:{value:(_vm.periodoDesde),callback:function ($$v) {_vm.periodoDesde=$$v},expression:"periodoDesde"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"label":"Período hasta","dense":"","outlined":"","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)","rules":_vm.rules.required.concat(
                  [_vm.rules.validDateRange(_vm.periodoDesde, _vm.periodoHasta)],
                  _vm.rules.periodoYyyyMm
                )},model:{value:(_vm.periodoHasta),callback:function ($$v) {_vm.periodoHasta=$$v},expression:"periodoHasta"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)","label":"Orden","dense":"","outlined":"","rules":_vm.rules.positiveNumber},model:{value:(_vm.orden),callback:function ($$v) {_vm.orden=$$v},expression:"orden"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-switch',{staticClass:"py-0",attrs:{"label":"Acumulable","dense":"","outlined":"","disabled":_vm.disabledAcumulableSwitch},model:{value:(_vm.acumulable),callback:function ($$v) {_vm.acumulable=$$v},expression:"acumulable"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.set}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }